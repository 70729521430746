import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';


function Animation() {
    const mountRef = useRef(null);

    useEffect(() => {
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setPixelRatio(window.devicePixelRatio);

        const vertexShader = `
                varying vec2 vUv;
                void main() {
                    vUv = uv;
                    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                }
            `;

        const fragmentShader = `
        uniform float time;
        uniform vec2 center;
        uniform vec3 color1; // Static gradient start color
        uniform vec3 color2; // Static gradient end color
        varying vec2 vUv;
            
        void main() {
            float distance = length(vUv - center);
        
            // Inner Circle Pulsating Effect (remains the same)
            float pulsate = sin(time * 3.0) * 0.5 + 0.5;
            float radiusInner = 0.3 + pulsate * 0.05; // Adjust radius for pulsating effect
        
            // Outer Circle Pulsating Effect
            float outerPulse = sin(time * 3.0 - 1.5) * 0.5 + 0.5; // Create a pulsating effect
            float radiusOuter = 0.35 + outerPulse * 0.1; // Outer circle pulsates in size
        
            // Color Logic (remains the same)
            vec3 gradientColor = mix(color1, color2, sin(distance * 10.0 - time * 1.0) * 0.5 + 0.5);
        
            // Determine if within inner or outer circle and mix colors accordingly
            float insideInnerCircle = step(distance, radiusInner);
            float insideOuterCircle = step(distance, radiusOuter);

            // Original line:
            // float insideInnerCircle = step(distance, radiusInner);
            // float insideOuterCircle = step(distance, radiusOuter);
                    
            // New lines for smoother edges:
            // float edgeWidth = 0.005; // Adjust for smoother or sharper edges
            // float insideInnerCircle = smoothstep(radiusInner - edgeWidth, radiusInner + edgeWidth, distance);
            // float insideOuterCircle = smoothstep(radiusOuter - edgeWidth, radiusOuter + edgeWidth, distance);

        
            vec3 color = mix(gradientColor, vec3(0.0, 0.0, 0.0), 1.0 - (insideInnerCircle + insideOuterCircle));
            gl_FragColor = vec4(color, 1.0);
        }

        `;

        const shaderMaterial = new THREE.ShaderMaterial({
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
            uniforms: {
                time: { value: 0.0 },
                center: { value: new THREE.Vector2(0.5, 0.5) },
                color1: { value: new THREE.Color(0xff0000) }, // Example gradient start color
                color2: { value: new THREE.Color(0x0000ff) }, // Example gradient end color
            }
        });

        const backgroundGeometry = new THREE.PlaneGeometry(10, 10);
        const backgroundMesh = new THREE.Mesh(backgroundGeometry, shaderMaterial);
        backgroundMesh.position.z = -1;
        scene.add(backgroundMesh);

        renderer.setClearColor("#000000");
        renderer.setSize(window.innerWidth, window.innerHeight);
        mountRef.current.appendChild(renderer.domElement);


        const outerRadius = 2;
        const innerRadius = outerRadius - 0.2;
        const thetaSegments = 1000;

        const material = new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.DoubleSide });

        // Group for the half-circles
        const group = new THREE.Group();

        const initialSpacing = 0; // Start with no spacing, i.e., a full circle
        const finalSpacing = 1.5; // Final spacing for separation

        // Left half-circle
        const leftGeometry = new THREE.RingGeometry(innerRadius, outerRadius, thetaSegments, 1, Math.PI / 2, Math.PI);
        const leftHalf = new THREE.Mesh(leftGeometry, material);
        leftHalf.position.y += initialSpacing; // Start at initial position
        group.add(leftHalf);

        // Right half-circle
        const rightGeometry = new THREE.RingGeometry(innerRadius, outerRadius, thetaSegments, 1, -Math.PI / 2, Math.PI);
        const rightHalf = new THREE.Mesh(rightGeometry, material);
        rightHalf.position.y -= initialSpacing; // Start at initial position
        group.add(rightHalf);

        scene.add(group);

        camera.position.z = 7;

        // Function to separate the half-circles
        const separateHalfCircles = () => {
            const separationSpeed = 0.05; // Speed of separation
            const separation = () => {
                if (leftHalf.position.y < finalSpacing) {
                    leftHalf.position.y += separationSpeed;
                    rightHalf.position.y -= separationSpeed;
                } else {
                    // Once separated, start the continuous rotation
                    animate();
                    return; // Stop the separation animation
                }
                requestAnimationFrame(separation);
            };
            separation();
        };


        // Continuous rotation animation
        const animate = () => {
            requestAnimationFrame(animate);
            const time = shaderMaterial.uniforms.time.value += 0.01;
            shaderMaterial.uniforms.color1.value.setHSL((Math.sin(time * 0.2) + 0.5), 1.0, 0.5);
            shaderMaterial.uniforms.color2.value.setHSL((Math.cos(time * 0.2) + 0.5), 1.0, 0.5);
            group.rotation.z += 0.01;
            renderer.render(scene, camera);
        };

        // Start with separating the half-circles
        separateHalfCircles();

        return () => {
            mountRef.current.removeChild(renderer.domElement);
        };
    }, []); // Empty dependency array to avoid re-creating the scene

    return <div ref={mountRef}></div>;
}

export default Animation;