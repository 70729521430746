import React from 'react';
import Animation from '../components/Animation';
import './Home.css'

function Home() {
    return (
        <div className="animation-container">
            <Animation />
        </div>
    );
}

export default Home;